import React, { useState, useEffect } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';
const { SelectOption } = Mobiscroll;
import './index.css';
import { withTranslation } from '../../lib/translate';

const MobileInput = ({ __, mobile_code, mobile_value, setMobileCode, setMobileValue, className }) => {
  const countryCodeData = getConfig().country_code_data || [
    {
      "text": "GB +44",
      "value": "+44"
    }, 
    {
      "text": "US +1",
      "value": "+1"
    }
  ];

  useEffect(() => {
    if (!mobile_code) {
      setMobileCode(countryCodeData[0].value);
    }
  }, [mobile_code, setMobileCode, countryCodeData]);

  return (
    <IonItem lines="none" className={`input-field-wrapper inline-input-field-wrapper ${className ? className : ''}`}>
      <div>
        <SelectOption
          onSet={(e, val) => setMobileCode(val.getVal())}
          display="center"
          value={mobile_code}
          data={countryCodeData}
        />
      </div>
      <IonInput
        onIonInput={(e) => setMobileValue(e.target.value)}
        clearInput
        required={false}
        type="tel"
        pattern="tel"
        inputmode="tel"
        placeholder="XXXXXXXXXXXX"
        value={mobile_value}
      />
    </IonItem>
  );
};

export default withTranslation(MobileInput);
